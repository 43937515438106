<template>
    <section class="">
        <header-page title="Foro" :params="params">
            <div class="col-auto ml-auto">
                <div class="bg-general cr-pointer text-white br-12 py-2 px-3 shadow" @click="hacerPregunta">
                    Hacer pregunta
                </div>
            </div>
        </header-page>
        <div v-loading="loading" class="custom-scroll overflow-auto pt-3 px-4" style="height:calc(100vh - 215px);">
            <div class="row mx-0 mb-5">
                <div class="col-auto d-middle f-18 text-gris f-600">
                    {{ curso.nombre }}
                </div>
                <div class="col-lg-4 ml-auto">
                    <el-input v-model="search" class="w-100 input-radius-12" suffix-icon="icon-search" placeholder="Buscar" />
                </div>
            </div>
            <div v-for="(foro, idx) in filtroForo" :key="idx" class="row mx-0 mt-2 border-bottom py-4">
                <div class="col-12">
                    <div class="row mx-0">
                        <div class="j-center d-middle px-0" style="width:84px;">
                            <div v-show="foro.nuevo" class="bg-purple text-white br-5 f-15 f-600 py-1 px-2">
                                NUEVO
                            </div>
                        </div>
                        <div class="col px-3 tres-puntos cr-pointer" @click="verForo(foro)">
                            <!-- <router-link :to="{name: 'foro.detalle', params:{id_foro: foro.id}}"> -->
                            <p class="text-gris f-600 f-18 tres-puntos">
                                {{ foro.titulo }}
                            </p>
                            <!-- </router-link> -->
                            <p class="text-gris f-600 f-15 mt-2 tres-puntos descripcion">
                                {{ foro.texto }}
                            </p>
                        </div>
                        <div class="col-auto">
                            <div class="row mx-0 f-18 text-gris">
                                <el-tooltip placement="bottom" content="Visualizacion" effect="light">
                                    <div class="col-auto">
                                        <i class="icon-eye text-general" />
                                        {{ foro.visualizaciones_count }}
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="row mx-0 mt-2 f-18 text-gris">
                                <el-tooltip placement="bottom" content="Respuestas" effect="light">
                                    <div class="col-auto">
                                        <i class="icon-message-arrow-left text-general" />
                                        {{ foro.mensajes_count }}
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <div class="row mx-0">
                        <div style="width:84px;" />
                        <div class="col px-0 d-flex">
                            <img :src="foro.creador.imagen" width="55" height="55" class="position-absolute obj-cover rounded-circle border-grey" style="top:-12px;left:-9px;" />
                            <div class="ticket-name py-1 pr-2 pl-5 tres-puntos text-center" style="min-width:170px;">
                                {{ foro.creador.nombre }}
                            </div>
                        </div>
                        <div class="col-auto px-5" />
                    </div>
                </div>
            </div>
            <div v-if="cursosForos.length <= 0" class="row mx-0">
                No existen foros
            </div>
        </div>
        <!-- Partials -->
        <modal-hacer-preguntas ref="HacerPreguntas" @actualizar="listarForosCurso" />
    </section>
</template>

<script>

import foros2 from '~/services/foros/foros'

export default {
    components: {
        modalHacerPreguntas: () => import('./partials/modalHacerPregunta')
    },
    data(){
        return {
            id_curso: parseInt(this.$route.params.id_curso),
            params: {
                id_curso: parseInt(this.$route.params.id_curso)
            },
            curso:{
                id: null,
                nombre: null
            },
            cursosForos: [],
            search: null,
            loading: false
        }
    },
    computed:{
        filtroForo(){
            if(this.search){
                return this.cursosForos.filter(f=> {
                    const hasTitle = f.titulo.toLowerCase().includes(this.search.toLowerCase())
                    const hasText = f.texto.toLowerCase().includes(this.search.toLowerCase())
                    return hasTitle || hasText
                })
            }else{
                return this.cursosForos
            }
        }
    },
    mounted(){
        this.search = null
        this.listarForosCurso()
    },
    methods:{
        hacerPregunta(){
            this.$refs.HacerPreguntas.toggle();
        },
        async listarForosCurso(){
            try {
                this.loading = true
                const {data:{data}} = await foros2.listarForosCurso({id_curso: this.id_curso})
                this.loading = false
                this.cursosForos = data.cursosForos
                this.curso = data.curso
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        async verForo(foro){
            try {
                this.$router.push({name: 'foro.detalle', params:{id_foro: foro.id}})
                const payload = {
                    id_foro: foro.id
                }
                const {data} = await foros2.insertVisualizaciones(payload)
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.ticket-name{
    background-color: #D9DCE7;    
    color: var(--color-grey);
    border-radius: 0px 20px 20px 0px;
}
.border-grey{
    border: 1px solid var(--color-grey);
}
.descripcion{
    height: 35px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>